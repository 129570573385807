import dayjs from 'dayjs';
import { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { CLIENT_GSERVICE_URL } from 'src/constants';
import { IResponseOil } from 'src/interface/widgets-response';
import styled from '@emotion/styled';
import SectionHeader from 'src/components/sections/section-header';

const WidgetOilStat: NextPage = () => {
  const [dataOil, setDataOil] = useState<IResponseOil | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const res: Response = await fetch(`${CLIENT_GSERVICE_URL}/oil`);
        const resJson: IResponseOil = await res.json();
        if (!resJson) throw new TypeError();
        setDataOil(resJson);
      } catch (err) {
        console.error('==========> WIDGET_OIL ERROR :', err);
      }
    })();
  }, []);

  const dataUpdate: string = dayjs(dataOil?.datetime).locale('th').format('DD MMM YYYY');

  return (
    <WidgetWrapper>
      {/* {dataOil !== null && ( */}
      <div className='widget-oil-stat'>
        <SectionHeader data={'ราคาน้ำมันวันนี้'} link={'#'} showReadmore={false} />
        <WidgetBox>
          <div className='widget-head'>
            <p>บาท/ลิตร</p>
          </div>
          {dataOil?.Diesel && <ul className='widget-oil-stat-list'>
            <li className='--diesel'>ดีเซล</li>
            <li className='border-none'><strong>{dataOil?.Diesel}</strong></li>
          </ul>}
          {dataOil?.DieselB10 && <ul className='widget-oil-stat-list'>
            <li className='--benzin'>ดีเซล B10</li>
            <li className='border-none'><strong>{dataOil?.DieselB10}</strong>  </li>
          </ul>}
          {dataOil?.Gasohol95 && <ul className='widget-oil-stat-list'>
            <li className='--gasohol95'>แก๊สโซฮอล์ 95</li>
            <li className='border-none'><strong>{dataOil?.Gasohol95}</strong> </li>
          </ul>}
          {dataOil?.Gasohol91 && <ul className='widget-oil-stat-list'>
            <li className='--gasohol91'>แก๊สโซฮอล์ 91</li>
            <li className='border-none'><strong>{dataOil?.Gasohol91}</strong> </li>
          </ul>}
          {dataOil?.GasoholE20 && <ul className='widget-oil-stat-list'>
            <li className='--e20'>แก๊สโซฮอล์ E20</li>
            <li className='border-none'><strong>{dataOil?.GasoholE20}</strong> </li>
          </ul>}
        </WidgetBox>
        <p className='date-update-widget'>อัพเดท {dataUpdate}</p>
      </div>
      {/* )} */}
    </WidgetWrapper>
  );
};

export default WidgetOilStat;

const WidgetWrapper = styled.div`
  p {
    margin: 0;
  }
  .widget-oil-stat {
    h2 {
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 18px;
      @media (max-width: 475px) {
        margin-bottom: 10px;
      }
    }

    .widget-oil-stat-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .widget-oil-stat-list {
        width: 100%;
        margin: 4px auto;
        display: flex;
        justify-content: space-between;
        .oil-stat-text {
          background: #3ba559;
          background: linear-gradient(90deg, #3ba559 10%, #3ba559 100%);
          border-radius: 15px;
          padding: 9.5px 25px;
          box-shadow: 3px 3px 9px rgba(65, 65, 65, 0.1);
        }

        &.--head {
          background: none;
          box-shadow: none;
          padding: 6px 25px;
          border-radius: 0;
          border-bottom: 1px solid #808080;
          p {
            color: #000;
          }
        }

        p {
          font-weight: 500;
          font-size: 14px;
          span,
          strong {
            color: #2f2f2f;
            font-weight: 400;
            font-size: 13px;
          }

          span {
            margin-left: 3px;
            color: #777;
          }
        }

        @media (max-width: 475px) {
          width: 100%;
        }
      }
    }
  }
  .date-update-widget {
    font-size: 12px;
    text-align: right;
    margin-top: 5px;
  }
`;



const WidgetBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  .widget-head{
    display: flex;
    width:100%;
    justify-content: flex-end;
    font-size: 14px;
    color:  #000;
    margin: 0px 10px 0px 5px;
    border-bottom: 1px solid #808080;
    font-weight: 700;
  }
  ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    li {
      &:nth-child(1) {
        text-align: start;
      }
      &.--benzin {
        border-left: 10px solid #ffc107;
        background:#ffc107;
      }
      &.--diesel {
        border-left: 10px solid #163f90;
        background:#163f90;
      }
      &.--gasohol91 {
        border-left: 10px solid #00b22e;
        background:#00b22e;
      }
      &.--gasohol95 {
        border-left: 10px solid #fe5900;
        background:#fe5900;
      }
      &.--e20 {
        border-left: 10px solid #6fd100;
        background:#6fd100;
      }
      &.border-none{
        background: #fff;
        box-shadow: unset;
        text-align: right;
        padding: 8px  10px 8px 20px;
        color: #000;
      }

      color: #fff;
      width: 100%;
      font-size: 14px;  
      background: rgb(252, 252, 252, 0.8);
      padding: 8px 20px;
      border-radius: 15px;
      box-shadow: 3px 3px 9px rgba(65, 65, 65, 0.1);
      text-align: center;
      margin: 5px;
      p {
        padding-left: 5px;
        font-size: 10px;
      }
    }
  }
`;